label.label-color {
    color: var(--whiteLight);
    font-size: 12px;
}
.select-option .MuiInputBase-root {
    width: 100%;
}
.select-option fieldset,
.select-option .MuiInputBase-formControl:hover fieldset {
    border-color: var(--whiteLight);
}
.select-option .MuiInputBase-colorPrimary:hover fieldset,
.select-option .MuiInputBase-colorPrimary.Mui-focused fieldset{
    border-color: var(--primeryColor);
}
.select-option .MuiSelect-select,
.select-option .MuiInputBase-colorPrimary input {
    color: var(--white);
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 14px;
}
.select-option .MuiInputBase-colorPrimary svg {
    fill: var(--whiteLight);
}

.event-graph-wrapper{
    /* max-height: calc(100vh - 250px); */
    /* height: 100%; */
    display: flex;
    justify-content: center;
}



.MuiPickersPopper-root .MuiPaper-elevation {
    background: var(--darkGreen);
    color: var(--whiteLight);;
}
.MuiPickersPopper-root  svg.MuiSvgIcon-root {
    color: var(--whiteLight);;
}
.MuiPickersPopper-root .MuiDayCalendar-weekDayLabel {
    color: var(--primeryColor);
}
.MuiPickersPopper-root button.MuiPickersDay-root {
    color: var(--whiteLight);;
}
.MuiPickersLayout-root li.Mui-selected.Mui-selected,
.MuiPickersPopper-root button.MuiPickersDay-root.Mui-selected {
    background: var(--primeryColor);
    color: var(--darkGreen);
}

.MuiPickersPopper-root button.MuiPickersDay-root.MuiPickersDay-today:not(.Mui-selected){
    border-color: var(--primeryColor);
    color: var(--primeryColor);
}

.MuiPickersPopper-root button.Mui-disabled:not(.Mui-selected) {
    color: rgb(255 255 255/20%);
}
.MuiDialogActions-spacing.MuiPickersLayout-actionBar button.MuiButton-text {
    color: var(--darkGreen);
    background-color: var(--primeryColor);
}
.MuiPickersPopper-root .MuiPickersFadeTransitionGroup-root ::-webkit-scrollbar,
.MuiPickersLayout-contentWrapper .MuiMultiSectionDigitalClockSection-root::-webkit-scrollbar{
    width: 4px;
}
.MuiPickersPopper-root .MuiDateCalendar-root .Mui-selected:hover,
.MuiPickersPopper-root .MuiDateCalendar-root .Mui-selected{
    background-color: var(--primeryColor);
    color: var(--darkGreen);
}

.canvasjs-chart-credit{
    display: none;
}
.btn-wrapper .loading-btn {
    padding: 9px 20px;
    background: var(--primeryColor);
    color: var(--darkGreen);
    border-radius: 200px;
    font-weight: 600;
    transition: all 0.4s ease-in-out;
}
.btn-wrapper .loading-btn:hover{
    background: var(--primeryColor);
    color: var(--darkGreen);
    opacity: 0.8;
}
.btn-wrapper .loading-btn.MuiLoadingButton-loading{
    background-color: var(--primeryColor);
    color: transparent;
}
.text-primary {
    color: var(--primeryColor);
}
@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .date-time-input input.MuiInputBase-inputAdornedEnd {
        font-size: 12px;
    }
}