.my-patients-card-container {
    border: 1px solid rgb(255, 255, 255, 50%);
    padding: 24px 15px;
    border-radius: 12px;
    background-color: var(--darkGreen);
}

.my-patients-card-container .top-view {
    display: flex;
    text-align: left;
}

.my-patients-card-container .top-view .user-name {
    font-weight: 400;
    color: var(--primeryColor);
}

.my-patients-card-container .top-view .address {
    font-size: 14px;
    color: rgb(255, 255, 255, 50%);
}

.user-info-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--lightBlack);
    border-radius: 10px;
    padding: 11px 16px;
    margin-top: 24px;
}

.user-info-box .title {
    color: rgb(255, 255, 255, 50%);
    font-size: 12px;
    font-weight: 400;
}

.user-info-box .sub-title {
    color: var(--primeryColor);
    font-weight: 400;
    margin-top: 4px;
}
.user-image-box {
    max-width: 75px;
}
.user-image-box img {
    width: 100%;
    border-radius: 50%;
}
.patents-details-wrapper .user-info-box{
    background-color: transparent;
    border: 1px dashed var(--whiteLight);
}
.border-dotted {
    border-right: 0.5px dotted rgb(255, 255, 255, 50%);
    height: 48px;
}

.goal-set-box {
    text-align: left;
    border-bottom: 1px dotted;
    border-color: rgb(255, 255, 255, 50%);
    padding-bottom: 14px;
}

.goal-set-box .title {
    font-size: 14px;
    font-family: 'Maitree';
    color: var(--primeryColor);
}

.goal-set-box .sub-title {
    font-size: 12px;
    color: rgb(255, 255, 255, 50%);
    margin-top: 3px;
}

.card-footer {
    display: flex;
    justify-content: space-between;
    margin-top: 17px;
    text-align: left;
    align-items: center;
    gap: 10px;
}
.card-footer .title{
    color: var(--primeryColor);
    font-size: 14px;
    font-family: 'Maitree';
}
.card-footer .sub-title{
    color: rgb(255, 255, 255, 50%);
    font-size: 12px;
} 
.profile-btn button {
    color: rgb(255, 255, 255, 50%);
    border-color: rgb(255, 255, 255, 50%);
    border-radius: 30px;
}