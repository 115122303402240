.dashboard-container {
    display: flex;
    background-color: var(--darkGreen);
    min-height: 100vh;
}

.dashboard-container .left-container {
    width: 230px;
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    overflow: auto;
    transition: all 0.4s ease-in-out;
}

.dashboard-container .right-container {
    width: calc(100% - 230px);
    margin-left: 230px;
    background-color: var(--lightBlack);
    border-radius: 20px 0px 0px 20px;
    padding: 23px 34px;
}

.dash-graph-container {
    display: flex;
}


.dash-graph-container .left-section .graph-img img {
    width: 100%;
}

.dash-graph-container .left-section .heading {
    color: var(--primeryColor);
    text-align: left;
    font-weight: 400;
}


.dash-graph-container .right-section .heading {
    color: var(--primeryColor);
    text-align: left;
    font-weight: 400;
}

.controller-status-card .cont-card {
    background-color: var(--darkGreen);
    border: 1px solid;
    border-color: var(--whiteLight);
    padding: 29px 27px;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.controller-status-card .cont-card .total-assig-contoller {
    color: var(--whiteLight);
    text-align: left;
    font-weight: 400;
    font-family: 'Acumin Pro';
}

.controller-status-card .cont-card .count {
    color: var(--whiteLight);
    text-align: left;
    font-weight: bold;
}

.firstIndex {
    background-color: red;
}

.header-menu-toggle {
    display: none;
}