.dashboard-card-container {
    border-radius: 10px;
    padding: 18px 16px;
    background-color: #FFA6C7;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.dashboard-card-container .card-content{
    display: flex;
    justify-content: space-between;
    align-items: center;
   margin-top: 12px;
}


.dashboard-card-container .card-title {
    font-weight: 400;
    color: var(--darkGreen);
    text-align: left;
}
.dashboard-card-container .card-content .count-number{
    color: var(--darkGreen);
   
}