.main-contant.profile-page {
    height: calc(100% - 60px);
    display: flex;
    align-items: center;
}

.profile-wrapper {
    background: var(--darkGreen);
    border-radius: 1rem;
    max-width: 900px;
    margin: auto;
}

.profile-inner {
    padding: 1rem 2rem;
}

.profile-header {
    padding: 1rem 1rem 2rem 1rem;
}

.select-form-group label.MuiFormLabel-root {
    position: static;
    transform: none;
}

.select-form-group label.MuiFormLabel-root {
    position: static;
    transform: none;
    font-family: 'Acumin Pro';
    color: rgb(255 255 255/50%);
    margin-bottom: 2px;
}

.select-form-group .MuiFormControl-root .MuiSelect-select {
    padding: 0.789rem 2.3rem 0.789rem 1rem;
    -webkit-text-fill-color: var(--white);
    color: var(--white);
}

.select-form-group .MuiFormControl-root svg {
    fill: rgb(255 255 255/50%);
}

.select-form-group .MuiInputBase-root fieldset,
.select-form-group .MuiInputBase-root:hover fieldset {
    border-color: var(--whiteLight);
}

.select-form-group .MuiInputBase-root.Mui-focused fieldset {
    border-width: 1px;
    border-color: var(--primeryColor);
}