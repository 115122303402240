@media screen and (max-width: 1366px) {
    h1.MuiTypography-root{
        font-size: 45px;
    }
    h2.MuiTypography-root{
        font-size: 42px;
    }
    h3.MuiTypography-root{
        font-size: 36px;
    }
    h4.MuiTypography-root{
        font-size: 24px;
    }
    h5.MuiTypography-root{
        font-size: 20px;
    }
    h6.MuiTypography-root{
        font-size: 14px;
    }
    .btn-wrapper button.btn-small.only-icon-btn {
        min-width: 42px;
    }
    .btn-font-small .btn-wrapper button, .btn-font-small .btn-wrapper button:hover ,
    .right .btn-font-small .btn-wrapper button,.right .btn-font-small .btn-wrapper button:hover {
        font-size: 14px;
    }
    .right .btn-wrapper button.btn-small.only-icon-btn svg {
        width: 37px;
        height: 40px;
    }
    .profile-btn button {
        font-size: 12px;
        padding: 5px 12px;
    }
    .main-contant .card-footer .title,
    .main-contant .goal-set-box .title,
    .main-contant .my-patients-card-container .top-view .address {
        font-size: 12px;
    }
    .main-contant .card-footer .sub-title,
    .main-contant .goal-set-box .sub-title {
        font-size: 10px;
    }
    p.MuiTypography-root,
    .sidebar-menu-container .menu-div a{
        font-size: 14px;
    }
    .top-view .user-image-box {
        max-width: 65px;
    }
    .App .login-main{
        align-items: center;
    }
    .App .login-main .right-section .logo-box {
        margin: 1rem 0 1rem 0;
    }
    .login-main .login-details h2 {
        font-size: 34px;
    }
    .login-main .btn-wrapper button, .login-main .btn-wrapper button:hover {
        font-size: 18px;
    }
    .login-main .login-container .login-main .right-section {
        padding: 0 0 0 2rem;
    }
    
}
@media screen and (max-width: 1280px) {
    
}
@media screen and (max-width: 1200px) {
    
}
@media screen and (max-width: 1023px) {
    div .dashboard-container .left-container{
        left: -100%;
    }
    div .dashboard-container .right-container{
        margin-left: 0;
        width: 100%;
        padding: 20px 15px;
    }
    .left-section .header-menu-toggle{
        display: flex;
        align-items: center;
        gap: 0.254rem;
    }
    .profile-modal .btn-center {
        margin: 0 auto;
    }
}
@media screen and (max-width: 991px) {
    .left-section .search-input{
        width: 260px;
    }
    .mobile-logo img{
        max-width: 120px;
    }
    h1.MuiTypography-root{
        font-size: 40px;
    }
    h2.MuiTypography-root{
        font-size: 38px;
    }
    h3.MuiTypography-root{
        font-size: 30px;
    }
    h4.MuiTypography-root{
        font-size: 20px;
    }
    h5.MuiTypography-root{
        font-size: 18px;
    }
    h6.MuiTypography-root{
        font-size: 14px;
    }
    .App .login-container .login-main .left-section .heading {
        font-size: 28px;
    }
}
@media screen and (max-width: 767px) {
    .header-profile--wrapper .profile-image+span{
        display: none;
    }
    .right-section .header-profile--wrapper .profile-image{
        margin: 0;
    }
    .mobile-logo img{
        max-width: 90px;
    }
    .profile-btn button {
        padding: 5px 10px;
        font-size: 12px;
    }
    .dashboard-container .header-container .right-section {
        gap: 0.5rem;
    }
    .header-container .left-section form.MuiPaper-root{
        display: none;
    }
    .btn-font-small .btn-wrapper button, .btn-font-small .btn-wrapper button:hover ,
    .right .btn-font-small .btn-wrapper button,.right .btn-font-small .btn-wrapper button:hover {
        font-size: 12px;
        padding: 8px 10px;
    }
    .btn-font-small .btn-wrapper button .MuiButton-startIcon {
        margin-right: 4px;
    }
    .right .btn-wrapper button.btn-small.only-icon-btn {
        min-width: 38px;
    }
    .right .btn-wrapper button.btn-small.only-icon-btn svg {
        width: 32px;
        height: 35px;
    }
    .App .login-container .login-main .left-section {
        display: none;
    }
    .App .login-container .login-main .right-section {
        width: 100%;
        padding: 0;
    }
    .login-main .btn-wrapper button, .login-main .btn-wrapper button:hover {
    font-size: 16px;
    }
    .login-main .login-details h2 {
        font-size: 26px;
    }
    .login-container .login-main {
        min-height: calc(100vh - 40px);
    }
    .App .login-container {
        padding: 20px;
    }
}

