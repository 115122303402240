.sidebar-container {
    background-color: var(--darkGreen);
    height: 100vh;
}

.logo-container {
    border-bottom: 0.5px solid;
    border-color: var(--sideBarBorderBottom);
    padding: 36px 20px 14px 20px;
    text-align: left;
}

.sidebar-menu-container {
    padding: 8px 20px;
    height: calc(100% - 88px);
    overflow: auto;
}

.sidebar-menu-container .menu-div a .sidebar-icon-box {
    min-width: 25px;
    margin-right: 10px;
}

.sidebar-menu-container .menu-div a .sidebar-icon-box svg {
    width: 100%;
}

.sidebar-menu-container .divider-color {
    border-color: var(--sideBarBorderBottom);
}

.sidebar-menu-container .menu-div a {
    padding: 12px 10px;
    border-radius: 10px;
    color: var(--whiteLight);
}

.sidebar-menu-container .menu-div a:hover,
.sidebar-menu-container .menu-div a.active {
    background-color: var(--primeryColor);
}

.sidebar-menu-container .menu-div a:hover svg path,
.sidebar-menu-container .menu-div a.active svg path {
    fill: var(--darkGreen);
    ;
}

.sidebar-menu-container .menu-div a:hover .svg-stroke svg path,
.sidebar-menu-container .menu-div a.active .svg-stroke svg path {
    fill: unset;
    stroke: var(--darkGreen);
}

.sidebar-menu-container .menu-div a:hover,
.sidebar-menu-container .menu-div a.active {
    color: var(--darkGreen);

}

.sidebar-menu-container :has(>a.active)+.MuiDivider-root,
.sidebar-menu-container :has(>a.active) :has(+.MuiDivider-root) {
    border-color: transparent;
}

.menu-name {
    text-wrap: nowrap;
}