.cstm-select-option .MuiSelect-select {
    color: aliceblue;
    text-align: left;
}
.cstm-select-option .MuiSelect-select span {
    color: var(--whiteLight);
}
.cstm-select-option svg {
    fill: var(--whiteLight);
}
.cstm-select-option input.MuiAutocomplete-input,
.readonly-intup-box .MuiInputBase-root input {
    color: #ffffff;
}
.cstm-select-option fieldset.MuiOutlinedInput-notchedOutline,
.cstm-select-option .MuiInputBase-root:hover fieldset.MuiOutlinedInput-notchedOutline{
    border-color: var(--whiteLight);
}
.cstm-select-option button.MuiButtonBase-root {
    color: var(--whiteLight);
}

.readonly-intup-box .MuiInputBase-root:has([readonly]) fieldset {
    border-color: var(--whiteLight);
    color: var(--white);
}
