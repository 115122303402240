.MuiPopover-root .MuiPopover-paper {
    box-shadow: 0 4px 10px rgb(255 255 255/25%);
}
.MuiPopover-paper ul {
    background-color: var(--darkGreen);
    padding: 0;
}
.MuiPopover-paper ul li{
    padding-top: 10px;
    padding-bottom: 10px;
}
.MuiPopover-paper ul li {
    padding-top: 10px;
    padding-bottom: 10px;
    color: var(--whiteLight);
    border-bottom: 1px solid var(--whiteLight);
}
.MuiPopover-paper ul li:last-child{
    border: none;
}
.MuiPopover-paper ul li  a{
    color: var(--whiteLight);
    text-decoration: none;
}
.MuiPopover-paper ul li:hover,
.MuiPopover-paper ul li.Mui-selected{
    background-color: rgba(145, 217, 184, 0.2);
    color: var(--white);
}
.MuiPopover-paper ul li:hover a{
    color: var(--white);
}
.select-option fieldset,

.select-option .MuiInputBase-formControl:hover fieldset {
    border-color: var(--whiteLight);
}
.select-option .MuiInputBase-colorPrimary.Mui-focused fieldset{
    border-color: var(--primeryColor);
}
.select-option .MuiSelect-select {
    color: var(--whiteLight);
}
.select-option .MuiInputBase-colorPrimary svg {
    fill: var(--whiteLight);
}

