:root {
  --black: #000000;
  --white: #ffffff;
  --darkGreen: #042835;
  --lightBlack: #021A22;
  --green:#062A37;
  --primeryColor: #91D9B9; 
  --redColor: #FF2C2C;
  --whiteLight:rgb(255, 255, 255, 40%);
  --boxShadowColor: rgb(255, 255, 255, 25%);
  --sideBarBorderBottom: rgb(255, 255, 255, 20%);
  --selectOptionActiveColor: rgba(145, 217, 184, 0.2);
  --graphTextColor: rgb(255 255 255/0.7);
  --pinkColor: #FFA6C7;
}
*{
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

@font-face {
  font-family: 'Acumin Pro';
  src: url('./Assets/font/Acumin-pro/AcuminPro-Regular.woff2') format('woff2'),
      url('./Assets/font/Acumin-pro/AcuminPro-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Maitree';
  src: url('./Assets/font/Maitree/Maitree-Medium.woff2') format('woff2'),
      url('./Assets/font/Maitree/Maitree-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Maitree';
  src: url('./Assets/font/Maitree/Maitree.woff2') format('woff2'),
      url('./Assets/font/Maitree/Maitree.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Maitree';
  src: url('./Assets/font/Maitree/Maitree-Bold.woff2') format('woff2'),
      url('./Assets/font/Maitree/Maitree-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
body {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: 'Acumin Pro' ;
  font-size: 16px;
}
/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
background: #f1f1f1; 
}

/* Handle */
::-webkit-scrollbar-thumb {
background: rgb(145 217 185 / 60%); 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
background: var(--primeryColor); 
}
h1.MuiTypography-root,h2.MuiTypography-root,h3.MuiTypography-root,h4.MuiTypography-root,h5.MuiTypography-root,h6.MuiTypography-root{
  font-family: 'Maitree';
  font-weight: 700;
}
h1.MuiTypography-root{
  font-size: 50px;
}
h2.MuiTypography-root{
  font-size: 46px;
}
h3.MuiTypography-root{
  font-size: 40px;
}
h4.MuiTypography-root{
  font-size: 28px;
}
h5.MuiTypography-root{
  font-size: 24px;
}
h6.MuiTypography-root{
  font-size: 16px;
}
p.MuiTypography-root {
  margin: 0px;
  padding: 0px;
  font-family: 'Acumin Pro';
}
.delete-btn-color{
  background-color: var(--redColor);
  color: var(--white);
}
.text-primery,
a.text-primery{
  color: var(--primeryColor);
}
.bg-darkGreen{
  background-color: var(--darkGreen);
}
.text-white,
a.text-white{
  color: var(--white);
}
a:hover{
  color: var(--primeryColor);
}
.text--right {
  text-align: right;
}
.text--left{
  text-align: left;
}
.graph-text{
  color: var(--graphTextColor);
}
.form-group{
  margin-bottom: 1.2rem;
}
.no-underline{
  text-decoration: none !important;
}
.border-bottom .line{
  position: relative;
  margin-bottom: 2px;
  display: inline-block;
  padding-bottom: 2px;
}
.border-bottom .line::after{
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  border-bottom: 1px solid var(--white);
  opacity: 50%;
}
.input-box>div {
  width: 100%;
}
.error-message {
  font-size: 14px;
  color: var(--redColor);
  text-align: right;
  margin-top: 5px;
}
.title-with-border {
  border-bottom: 1px solid var(--whiteLight);
}
.MuiFormControl-root{
  width: 100%;
}
.btn-wrapper {
  width: 100%;
}
.profile-modal {
  overflow: auto;
}
.profile-modal .modal-shadow {
  background-color: #042835;
  padding: 32px;
  border-radius: 20px;
}
.profile-modal .input-box .input-label, 
.profile-modal .select-opation--wrapper > div label {
  font-family: 'Acumin Pro';
  transform: none;
}
.cstm-phone--input input.PhoneInputInput::placeholder{
  color: var(--whiteLight);
  font-family: 'Acumin Pro';
}
.profile-modal .modal-header h4 {
  font-weight: 400;
}
.input-box .MuiFormControl-root input {
  padding: 0.72rem 1rem;
  /* -webkit-text-fill-color: var(--white); */
  border-radius: 6px;
}

.form-group .select-opation--wrapper .MuiOutlinedInput-root {
  padding-top: 4px;
  padding-bottom: 4px;
}
.input-box .MuiInputBase-root.Mui-focused input{
  border-color: var(--primeryColor);
}
.profile-btn button:hover {
  border-color: var(--primeryColor);
  color: var(--primeryColor);
}
.btn-font-small .btn-wrapper button,
.btn-font-small .btn-wrapper button:hover {
  font-size: 16px;
  text-wrap: nowrap;
}
.btn-wrapper button.btn-small.only-icon-btn{
  min-width: 50px;
}
.btn-wrapper button.outline-btn {
  background: transparent;
  border: 1px solid var(--whiteLight);
  color: var(--whiteLight);
}
.select-opation--wrapper .MuiAutocomplete-popper{
  box-shadow: 0 4px 10px var(--boxShadowColor);
}
.select-opation--wrapper .MuiAutocomplete-popper>div{
  background-color: var(--darkGreen);
}
.select-opation--wrapper .MuiAutocomplete-popper .MuiAutocomplete-option {
  color: var(--whiteLight);
  border-bottom: 1px solid var(--whiteLight);
  padding-top: 10px;
  padding-bottom: 10px;
}
.select-opation--wrapper .MuiAutocomplete-popper .MuiAutocomplete-option:last-child{
  border-bottom: none;
}
.select-opation--wrapper .MuiAutocomplete-popper .MuiAutocomplete-option:hover,
.select-opation--wrapper .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected='true'],
.select-opation--wrapper .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected ='true'] .Mui-focused {
  background-color: var(--selectOptionActiveColor);
  color: var(--white);
}

.profile-modal .modal-header svg {
  width: 26px;
}
.toggle-btn-box .MuiButtonBase-root,
.profile-modal .modal-header .MuiButtonBase-root {
  min-width: 40px;
}
.main-card{
  background-color: var(--darkGreen);
  border: 1px solid var(--whiteLight);
  border-radius: 5px;
  height: 100%;
}
.main-card-header{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px 1rem;
  gap: 0.845rem;
}
.main-card-body,
.main-card-footer {
  padding: 10px 1rem;
}
.main-card-body img{
  width: 100%;
}
.border-separate .MuiDivider-root {
  border-color: rgb(255 255 255/0.2);
}
.border-bottom-2{
  border-bottom: 2px solid rgb(255 255 255/0.1);
}
.dialog-component .MuiPaper-elevation {
  background: var(--darkGreen);
  color: var(--white);
  box-shadow: 0 4px 10px rgb(255 255 255/0.1);
  text-align: center;
  width: 100%;
}
.dialog-component .MuiPaper-elevation .text-white p{
  font-size: 20px;
  color: rgb(255 255 255/0.8);
}
.dialog-component .MuiPaper-elevation .modal-title {
  color: var(--primeryColor);
  border-bottom: 1px solid var(--whiteLight);
}
.dialog-component .MuiPaper-elevation .MuiDialogContent-root {
  padding-top: 1rem;
}

button.btn-delete,
.dialog-component .MuiPaper-elevation button.cancel-btn {
  background: var(--redColor);
  color: var(--white);
}
button.btn-delete,
.dialog-component .MuiPaper-elevation button.cancel-btn {
  background: var(--redColor);
  color: var(--white);
}
button.btn-primary,
.dialog-component .MuiPaper-elevation  button.primary-btn {
  background: var(--primeryColor);
  color: var(--darkGreen);
}
button.btn-primary:hover,
.dialog-component .MuiPaper-elevation  button.primary-btn:hover{
  background: var(--primeryColor);
  color: var(--darkGreen);
  opacity: 0.8;
}
button.btn-delete:hover,
.dialog-component .MuiPaper-elevation button.cancel-btn:hover {
  background: var(--redColor);
  color: var(--white);
  opacity: 0.8;
}
.cstm-toggle-btn .MuiSwitch-colorPrimary.Mui-checked {
  color: var(--primeryColor);
}
.cstm-toggle-btn .MuiSwitch-colorPrimary.Mui-checked+.MuiSwitch-track{
  background-color: var(--primeryColor);
}
.dialog-component .MuiPaper-elevation .modal-title:empty{
  display: none;
}
.dialog-component .MuiPaper-elevation .modal-title:empty+.MuiDialogContent-root{
  padding: 35px 1.5rem;
}
.dialog-component .MuiDialogActions-root.MuiDialogActions-spacing {
  padding: 0 1.5rem 25px 1.5rem;
  justify-content: center;
}
.dialog-component .MuiPaper-elevation .MuiDialogContent-root svg {
  width: 90px;
  height: 90px;
  margin: 30px auto;
}
.dialog-component .MuiPaper-elevation .MuiDialogContent-root svg path {
  fill: #FFC548;
}
.border-underline {
  padding-bottom: 4px;
  position: relative;
}
.border-underline::after {
  content: "";
  border-bottom: 1px solid;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
}