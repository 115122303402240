.input-box{
    width: 100%;
}
.input-wrap{
    border: 1px solid var(--green);
    border-radius: 5px;
}
.input-box label.Mui-focused,
.input-box .input-label, .input-box .input-label:focus{
    color: var(--white);
    font-size: 16px;
    position: static;   
    transform: none;
    text-align: left;
    margin-bottom: 2px;
}
.input-box input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 0s 600000s, color 0s 600000s !important;
}
.input-box .input-label span{
    color: red;
}
.input-box input {
    background: #062A37;
    border-color: var(--whiteLight);
    color: var(--white);
}

.input-box .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline,
.input-box .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: var(--primeryColor);
}

.input-box .MuiFormControl-root fieldset {
    border-color: var(--whiteLight);
}
.input-box .MuiFormControl-root .Mui-disabled + fieldset {
    border-color: var(--whiteLight) !important;
}