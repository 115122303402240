.btn-wrapper button, .btn-wrapper button:hover{
    background-color: var(--primeryColor);
    color: var(--darkGreen);
    font-size: 20px;
    font-weight: bold;
    font-family: 'Acumin Pro';
    width: 100%;
    padding: 0.6rem 1rem;
}
.btn-wrapper button.btn_w_auto{
    width: auto;
    min-width: 150px;
}
.btn-wrapper button.btn-outline-pink {
    background: transparent;
    border: 1px solid var(--pinkColor);
    color: var(--pinkColor);
    width: auto;
    min-width: 90px;
}
.btn-wrapper button.btn-outline-pink:hover{
    background: var(--pinkColor);
    color: var(--green);
}

.btn-wrapper button.btn-small {
    font-size: 8px;
    font-weight: 400;
    padding: 6px 10px;
}
.btn-wrapper button.btn-round{
    border-radius: 100px;
}
