.login-container {
    background-color: var(--darkGreen);
    overflow: hidden;
    min-height: 100vh;
    padding: 40px;
}

.login-main {
    min-height: calc(100vh - 80px);
    background-color: var(--lightBlack);
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
    border-radius: 2.5rem;
}

.login-container .login-main .left-section {
    text-align: center;
    position: relative;
    width: 50%;
}

.login-container .login-main .left-section .img-container {
    position: relative;
    display: inline-block;
}

.login-container .login-main .left-section .img-container img {
    max-height: calc(100vh - 130px);
    height: 100%;
    width: 100%;
    object-fit: contain;
    object-position: left;
}

.login-container .login-main .right-section {
    width: 50%;
    max-width: 500px;
    margin: 0 auto;
    padding: 0 2rem;
}

.login-main .right-section .heading--box,
.login-main .right-section .heading--box h2 {
    margin-bottom: 1rem;
}

.login-main .right-section .logo-box {
    margin: 7rem 0 4rem 0;
}

.login-container .login-main .left-section .heading {
    color: var(--darkGreen);
    font-size: clamp(32px, 2vw, 48px);
    font-weight: bold;
    text-align: left;
}

.login-container .login-main .left-section .text-container {
    position: absolute;
    bottom: 6%;
    left: 7%;
}

.login-details h2 {
    font-size: 40px;
    line-height: 1.5;
    font-weight: 400;
}

.right-section form {
    display: block;
}

.login-main .right-section form>div {
    width: 100%;
}

.remember-label {
    font-size: 12px;
    margin-left: 5px;
}

.pass-btn-group .MuiOutlinedInput-root {
    padding: 0;
}

.pass-btn-group .MuiInputBase-root input {
    border-right: 0;
    border-radius: 4px 0 0 4px;
}

.pass-btn-group .MuiInputAdornment-positionEnd {
    height: 100%;
    padding: 0;
    margin: 0;
}

.pass-btn-group .MuiInputAdornment-positionEnd button.MuiButtonBase-root {

    color: var(--whiteLight);
    border-radius: 0 4px 4px 0;
    background-color: #062a37;
}

.pass-btn-group .MuiInputBase-root {
    background-color: var(--darkGreen);
    color: var(--white);
}

/* OTP CSS START */
.otp-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.otp-wrapper input {
    margin: 5px;
    border: none;
    border-bottom: 2px solid var(--primeryColor);
    color: var(--primeryColor);
    background-color: transparent;
    outline: 0;
    font-size: 16px;
    line-height: 2.5;
    font-family: 'Acumin Pro';
    font-weight: 400;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.otp-wrapper .input-box .input-wrap {
    border: none;
}

/* OTO CSS END */

.btn-wrapper button,
.btn-wrapper button:hover {
    background-color: var(--primeryColor);
    color: var(--darkGreen);
    font-size: 20px;
    font-weight: bold;
    font-family: 'Acumin Pro';
    width: 100%;
    padding: 0.6rem 1rem;
}

.btn-wrapper button.btn_w_auto {
    width: auto;
    min-width: 150px;
}

.btn-wrapper button.btn-outline-pink {
    background: transparent;
    border: 1px solid var(--pinkColor);
    color: var(--pinkColor);
    width: auto;
    min-width: 90px;
}

.btn-wrapper button.btn-outline-pink:hover {
    background: var(--pinkColor);
    color: var(--green);
}

.btn-wrapper button.btn-small {
    font-size: 8px;
    font-weight: 400;
    padding: 6px 10px;
}

.btn-wrapper .loading-button.MuiLoadingButton-loading {
    background-color: var(--primeryColor);
    color: transparent;
}