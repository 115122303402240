.header-container {
    width: 100%;
    background-color: var(--darkGreen);
    padding: 14px;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 10px;
    z-index: 999;
}

.header-container::after,
.header-container::before {
    content: "";
    height: 10px;
    position: absolute;
    top: -10px;
    left: 0;
    right: 0;
    background-color: var(--lightBlack);
}

.header-container::after {
    top: auto;
    bottom: -10px;
}

/* .header-container .left-section{

} */
.header-container .right-section {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.header-container .right-section .MuiBox-root svg {
    color: rgb(255 255 255 / 40%);
}

.header-profile--wrapper .profile-image {
    width: 34px;
    height: 34px;
    border-radius: 50%;
    margin-right: 0.6rem;
}

.header-profile--wrapper .profile-image+span {
    max-width: 140px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.header-profile--wrapper button.h-profile--btn {
    background: transparent;
    padding: 0 1rem 0 0;
    color: rgb(255 255 255/50%);
    font-family: 'Acumin Pro';
    font-size: 16px;
}

.header-profile--wrapper button.h-profile--btn:last-child {
    padding: 0;
}

.header-profile--wrapper button.h-profile--btn .MuiButton-endIcon {
    transition: all 0.4s ease-in-out;
}

.header-profile--wrapper button.h-profile--btn[aria-expanded="true"] .MuiButton-endIcon {
    transform: rotate(180deg);
}

.header-menu .MuiPaper-root {
    background-color: var(--darkGreen);
}

#demo-customized-menu .MuiPaper-root {
    box-shadow: 0 6px 10px rgb(255 255 255/25%);

}

.header-menu ul .MuiDivider-root {
    border-color: var(--white);
    opacity: 40%;
}

.header-menu ul li {
    color: rgb(255 255 255/40%);
    font-family: 'Acumin Pro';
}

.header-menu ul li svg {
    fill: rgb(255 255 255/40%);
}

.header-container .left-section form.MuiPaper-rounded {
    background-color: var(--darkGreen);
    border-color: var(--whiteLight);
    border-radius: 10px;
}

.notification-box svg.MuiSvgIcon-root {
    height: auto;
    width: 32px;
}


.toggle-btn-box .MuiSvgIcon-root {
    color: var(--whiteLight);
    font-size: 30px;
    cursor: pointer;
    transition: all 0.4s ease-in-out;
}

.toggle-btn-box .MuiSvgIcon-root:hover {
    color: var(--white);
}

label.label-color {
    color: var(--whiteLight);
    font-size: 12px;
}

.select-option .MuiInputBase-root {
    width: 70%;
}