.my-patients-container {
    display: flex;
    background-color: var(--darkGreen);
}

.my-patients-container .my-patients-left-section {
    width: 15%;
}

.my-patients-container .my-patients-right-section {
    width: 85%;
    background-color: var(--lightBlack);
    border-radius: 20px 0px 0px 20px;
    padding: 23px 34px;
}

.my-patients-topbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    border-bottom: 1px solid rgb(255, 255, 255, 50%);
    padding-bottom: 12px;
    gap: 0.5rem;
}

.my-patients-topbar .title {
    font-weight: 400;
    color: var(--primeryColor);
}

.my-patients-topbar .right {
    display: flex;
    align-items: center;
    margin-left: auto;
}

.list-icon {
    margin-left: 19px;
}

.btn-wrapper {
    width: auto
}

.btn-wrapper button.btn-small.only-icon-btn {
    padding: 0;
    background: var(--darkGreen);
    border: 1px solid var(--whiteLight);
}

.btn-wrapper button.btn-small.only-icon-btn svg {
    width: 50px;
    height: 43px;
    fill: var(--whiteLight);
}

.main-card-header h4.MuiTypography-root {
    font-weight: 400;
}

.search-input {
    display: flex;
    align-items: center;
    width: 350px;
    max-height: 42px;
    background-color: var(--darkGreen);
    border: 1px solid rgb(150, 150, 150);
    color: var(--white);
}

.search-input input.MuiInputBase-input {
    color: var(--white);
}

form.MuiPaper-rounded {
    background-color: var(--darkGreen);
    border-color: var(--whiteLight);
    border-radius: 10px;
}

.btn-wrapper .thres-loading-btn {
    padding: 9px 20px;
    background: var(--primeryColor);
    color: var(--darkGreen);
    border-radius: 200px;
    font-weight: 600;
    transition: all 0.4s ease-in-out;
    margin: 3rem auto 0 auto;
}

.btn-wrapper .thres-loading-btn:hover {
    background: var(--primeryColor);
    color: var(--darkGreen);
    opacity: 0.8;
}

.btn-wrapper .thres-loading-btn.MuiLoadingButton-loading {
    background-color: var(--primeryColor);
    color: transparent;
}

.btn-wrapper .goal-loading-btn {
    background: var(--primeryColor);
    color: var(--darkGreen);
    font-weight: 600;
    transition: all 0.4s ease-in-out;
    margin: 3px auto 0 auto;
    max-width: 100px;
}

.btn-wrapper .goal-loading-btn:hover {
    background: var(--primeryColor);
    color: var(--darkGreen);
    opacity: 0.8;
}

.btn-wrapper .goal-loading-btn.MuiLoadingButton-loading {
    background-color: var(--primeryColor);
    color: transparent;
}