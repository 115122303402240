.profile-modal .modal-header {
    display: flex;
    justify-content: space-between;
}
.profile-modal .modal-body {
    padding-top: 1.5rem;
}
.profile-modal .btn-center {
    max-width: 400px;
    margin: 3rem auto 0 auto;
}
.select-opation--wrapper > div label {
    position: relative;
    color: var(--white);
}
.select-opation--wrapper > div {
    width: 100%;
}
.select-opation--wrapper input {
    padding: 8.5px 4px 8.5px 5px !important;
    color: var(--white);
}
.select-opation--wrapper button svg{
    fill: var(--white);
}
.select-opation--wrapper fieldset,
.cstm-phone--input .MuiAutocomplete-root fieldset,
.select-opation--wrapper [class*="-MuiOutlinedInput-roo"]:hover fieldset,
.cstm-phone--input .MuiAutocomplete-root [class*="-MuiOutlinedInput-roo"]:hover fieldset{
    border-color: rgb(255 255 255/40% );
}
.cstm-phone--input .MuiAutocomplete-root fieldset {
    border-right: none;
    border-radius: 4px 0 0 4px;
    height: 62px;
}
.cstm-phone--input .MuiAutocomplete-root>div{
    width: 100px;
    margin-bottom: 2px;
    background-color: var(--green);
}
.cstm-phone--input .MuiFormControl-root + form{
    width: calc(100% - 97px);
}
.select-opation--wrapper .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    background-color: var(--darkGreen);
}
/* .cstm-phone--input {
    display: flex;
    align-items: flex-end;
} */
.cstm-phone--input .MuiInputBase-root input {
    border-left: none;
    border-radius: 0 4px 4px 0;
    padding-left: 0;
    color: var(--white);
}
.cstm-phone--input .MuiFormControl-root + form label {
    transform: translateX(-100px);
    font-size: 78%;
}
.cstm-phone--input .MuiAutocomplete-root svg.MuiSvgIcon-root {
    fill: rgb(255 255 255/40% );
}
.cstm-file--input{
    position: relative;
}
.cstm-file--input input[type="file"] {
    padding-right: 110px;
    cursor: pointer;
    color: rgb(255 255 255/50%);
}
.cstm-file--input input[type="file"]::-webkit-file-upload-button {
    visibility: hidden;
    display: none;
}
.cstm-file--input button.file-upload--btn {
    position: absolute;
    right: 1px;
    bottom: 1px;
    background: rgb(255 255 255/50%);
    height: calc(100% - 26px);
    font-size: 16px;
    color: var(--darkGreen);
    padding: 0.5rem 1rem;
    border: 1px solid #707070;
    pointer-events: none;
}
.profile-modal .input-box .input-label,
.profile-modal .select-opation--wrapper > div label{
    color: rgb(255 255 255/50%);
}


.btn-wrapper{
    text-align: center;
}
.btn-wrapper .loading-btn {
    padding: 9px 20px;
    background: var(--primeryColor);
    color: var(--darkGreen);
    border-radius: 200px;
    font-weight: 600;
    transition: all 0.4s ease-in-out;
    max-width: 400px;
    margin: 3rem auto 0 auto;
}
.btn-wrapper .loading-btn:hover{
    background: var(--primeryColor);
    color: var(--darkGreen);
    opacity: 0.8;
}
.btn-wrapper .loading-btn.MuiLoadingButton-loading{
    background-color: var(--primeryColor);
    color: transparent;
}